import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField,
} from "react-admin";

const ParentUnitList = () => {
  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" label="Parent Unit ID" />
        <TextField source="name.pl" label="PL name" />
        <TextField source="name.ua" label="UA name" />
        <EditButton basePath="parent-unit" />
        <DeleteButton basePath="parent-unit" />
      </Datagrid>
    </List>
  );
};

export default ParentUnitList;
