import * as React from 'react';
import {
    useCreateSuggestionContext,
    useCreate,
} from "react-admin";
import {
    Box,
    Button,
    Chip,
    Dialog,
    DialogContent,
    DialogActions,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
} from '@mui/material';
import { whatToTakeTypes } from "./WhatToTakeCreateAndEdit";
import { SectionTitle } from "../common/SectionTitle";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const WhatToTakeCreateInDialog = (props) => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [valuePl, setValuePL] = React.useState(filter || '');
    const [valueUa, setValueUa] = React.useState('');
    const [types, setTypes] = React.useState([]);
    const [create] = useCreate();

    const handleSubmit = event => {
        event.preventDefault();
        create(
            'what-to-take',
            {
                data: {
                    name: {
                        pl: valuePl,
                        ua: valueUa || ''
                    },
                    types: types
                },
            },
            {
                onSuccess: ({ data }) => {
                    setValuePL('');
                    setValueUa('');
                    setTypes([]);
                    onCreate(data);
                },
            }
        );
    };

    return (
        <Dialog maxWidth={'sm'} fullWidth={true} open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <SectionTitle label="New item name" variant="h6" />
                    <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
                        <TextField
                            label="Polish"
                            multiline="true"
                            onChange={event => setValuePL(event.target.value)}
                            required
                            fullWidth={true}
                            value={valuePl}
                            variant="standard"
                            autoFocus
                        />
                    </Box>
                    <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
                        <TextField
                            label="Ukrainian"
                            multiline="true"
                            onChange={event => setValueUa(event.target.value)}
                            fullWidth={true}
                            value={valueUa}
                            variant="standard"
                        />
                    </Box>
                    <br/>
                    <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
                        <FormControl sx={{ m: 1 }} fullWidth={true} variant="outlined">
                        <InputLabel id="types-label">Types</InputLabel>
                        <Select
                            labelId="types-label"
                                multiple
                                fullWidth={true}
                                onChange={event => setTypes(event.target.value)}
                                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                                inputProps={{ 'aria-label': 'Without label' }}
                                value={types}
                            >
                                {whatToTakeTypes.map((item) => (
                                    <MenuItem
                                        key={item.id}
                                        value={item.value}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Save</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}