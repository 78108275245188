import {
  SimpleForm,
} from "react-admin";
import { MultiLanguageInputs } from './MultiLanguageInputs';

export const CreateAndEditValue = ({
  label,
  sourcePl,
  sourceUa,
  required,
  fullWidth,
}) => {
  return (
    <>
      <SimpleForm>
        <MultiLanguageInputs
          label={label}
          sourcePl={sourcePl}
          sourceUa={sourceUa}
          required={required}
          fullWidth={fullWidth}
        />
      </SimpleForm>
    </>
  );
};
