import {
  TextInput,
  required as reactAdminRequired,
} from "react-admin";
import { Box } from "@mui/material";
import { SectionTitle } from './SectionTitle';

export const MultiLanguageInputs = ({
  label,
  sourcePl,
  sourceUa,
  required,
  requiredUa,
  fullWidth,
}) => {
  let validation = [];
  let uaValidation = [];
  if (required === true) {
    validation = [reactAdminRequired()];
  }
  if (requiredUa === true) {
    uaValidation = [reactAdminRequired()];
  }

  return (
    <>
      <SectionTitle label={label} variant="h6" />
      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source={sourcePl}
            label="pl"
            validate={validation}
            fullWidth={fullWidth}
            multiline="true"
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput
            source={sourceUa}
            label="ua"
            fullWidth={fullWidth}
            multiline="true"
            validate={uaValidation}
          />
        </Box>
      </Box>
    </>
  );
};