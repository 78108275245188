import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField,
} from "react-admin";

const TagsList = () => {
  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" label="Tag ID" />
        <TextField source="name.pl" label="PL name" />
        <TextField source="name.ua" label="UA name" />
        <EditButton basePath="tags" />
        <DeleteButton basePath="tags" />
      </Datagrid>
    </List>
  );
};

export default TagsList;
