// in src/App.js
import * as React from "react";
import { Admin, Resource } from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import {
  WhatToTakeList,
  WhatToTakeCreate,
  WhatToTakeEdit,
} from "./components/what-to-take";
import {
  FacilityCreate,
  FacilityList,
  FacilityEdit,
} from "./components/facility";
import { SpecCreate, SpecList, SpecEdit } from "./components/spec";
import { TagsList, TagCreate, TagEdit } from "./components/tags";
import {
  AtLocationList,
  AtLocationCreate,
  AtLocationEdit,
} from "./components/at-location";
import {
  ParentUnitList,
  ParentUnitCreate,
  ParentUnitEdit,
} from "./components/parent-unit";
import { UserPoolId, ClientId } from "./CognitoAuthProvider/CognitoConfig";
import AuthProvider from "./CognitoAuthProvider/CognitoAuthProvider";

const dataProvider = jsonServerProvider(process.env.REACT_APP_URL);

const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={AuthProvider({ UserPoolId, ClientId })}
  >
    <Resource
      name="facility"
      list={FacilityList}
      create={FacilityCreate}
      edit={FacilityEdit}
    />
    <Resource
      options={{ label: "Specializations" }}
      name="specialities"
      list={SpecList}
      create={SpecCreate}
      edit={SpecEdit}
    />
    <Resource name="tags" list={TagsList} create={TagCreate} edit={TagEdit} />
    <Resource
      options={{ label: "What to take items" }}
      name="what-to-take"
      list={WhatToTakeList}
      create={WhatToTakeCreate}
      edit={WhatToTakeEdit}
    />
    <Resource
      options={{ label: "Parent Units" }}
      name="parent-unit"
      list={ParentUnitList}
      create={ParentUnitCreate}
      edit={ParentUnitEdit}
    />
    <Resource
      options={{ label: "At location" }}
      name="at-location"
      list={AtLocationList}
      create={AtLocationCreate}
      edit={AtLocationEdit}
    />
  </Admin>
);

export default App;
