import { Edit } from "react-admin";
import { CreateAndEditValue } from "../../common/CreateAndEditValue";

export const AtLocationEdit = () => (
  <Edit title="Edit At Location">
    <CreateAndEditValue
      label="At Location"
      sourcePl={"name.pl"}
      sourceUa={"name.ua"}
      required
      fullWidth
    />
  </Edit>
);
