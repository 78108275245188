import { Create } from "react-admin";
import { WhatToTakeCreateAndEdit } from "../WhatToTakeCreateAndEdit";

const WhatToTakeCreate = () => {
  return (
    <Create title="Create What To Take Item">
      <WhatToTakeCreateAndEdit
        label="What To Take Item"
        sourcePl={"name.pl"}
        sourceUa={"name.ua"}
        required
        fullWidth
      />
    </Create>
  );
};

export default WhatToTakeCreate;
