import { Edit } from "react-admin";
import { CreateAndEditValue } from "../../common/CreateAndEditValue";

const TagEdit = () => {
  return (
    <Edit title="Edit Tag">
      <CreateAndEditValue
        label="Tag"
        sourcePl={"name.pl"}
        sourceUa={"name.ua"}
        required
        fullWidth
      />
    </Edit>
  );
};

export default TagEdit;
