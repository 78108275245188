import * as React from "react";
import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField,
  EmailField,
  ReferenceField,
} from "react-admin";

export const FacilityList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField label="name" source="name.pl" />
      <TextField source="type" label="type" />
      <TextField source="info.city.pl" label="city" />
      <TextField source="info.address.pl" label="address" />
      <TextField source="info.district.pl" label="district" />
      <EmailField source="info.mail" label="Mail" />
      <ReferenceField
        label="Parent Unit"
        source="info.parent_unit"
        reference="parent-unit"
      >
        <TextField source="name.pl" />
      </ReferenceField>
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);
