import {
  TextInput,
  required as reactAdminRequired,
  SimpleForm,
  SelectInput,
  ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  useTranslate,
  WithRecord,
  AutocompleteInput,
} from "react-admin";
import { Box } from "@mui/material";
import { MultiLanguageInputs } from "../common/MultiLanguageInputs";
import { SectionTitle } from "../common/SectionTitle";
import { SelectWithSearchAndAdd } from "../common/SelectWithSearchAndAdd";
import { WhatToTakeCreateInDialog } from "../what-to-take/WhatToTakeCreateInDialog";
import ObjectGallery from "../common/S3Gallery/ObjectGallery";
import {
  BucketName,
  Region,
  AccessKeyId,
  SecretAccessKey,
  GalleryDirectory,
} from "../common/S3Gallery/S3Config";

const districts = [
  { id: "dolnoslaskie", pl: "dolnośląskie", ua: "Нижня Сілезія" },
  {
    id: "kujawskopomorskie",
    pl: "kujawsko-pomorskie",
    ua: "Куявсько-Поморське",
  },
  { id: "lubelskie", pl: "lubelskie", ua: "Люблінське" },
  { id: "lubuskie", pl: "lubuskie", ua: "Любуське" },
  { id: "lodzkie", pl: "łódzkie", ua: "Лодзьке" },
  { id: "malopolskie", pl: "małopolskie", ua: "Малопольське" },
  { id: "mazowieckie", pl: "mazowieckie", ua: "Мазовецьке" },
  { id: "opolskie", pl: "opolskie", ua: "Опольське" },
  { id: "podkarpackie", pl: "podkarpackie", ua: "Підкарпатське" },
  { id: "podlaskie", pl: "podlaskie", ua: "Підляське" },
  { id: "pomorskie", pl: "pomorskie", ua: "Поморське" },
  { id: "slaskie", pl: "śląskie", ua: "Сілезьке" },
  { id: "swietokrzyskie", pl: "świętokrzyskie", ua: "Свєнтокшиське" },
  {
    id: "warminskomazurskie",
    pl: "warmińsko-mazurskie",
    ua: "Вармінсько-Мазурське",
  },
  { id: "wielkopolskie", pl: "wielkopolskie", ua: "Великопольське" },
  {
    id: "zachodniopomorskie",
    pl: "zachodniopomorskie",
    ua: "Західнопоморське",
  },
];

const toChoices = (items) =>
  items.map((item) => ({ id: item.pl, name: item.pl, value: item }));

const renderInputsLinkValue = (scopedFormData, getSource) => {
  if (!scopedFormData) {
    return null;
  }

  switch (scopedFormData.type) {
    case "link":
      return (
        <>
          <SectionTitle label="link" variant="h6" />
          <TextInput
            source={getSource("link")}
            label="link"
            validate={[reactAdminRequired()]}
            fullWidth
          />
        </>
      );
    case "value":
      return (
        <MultiLanguageInputs
          label="value"
          sourcePl={getSource("value.pl")}
          sourceUa={getSource("value.ua")}
          required
          fullWidth
        />
      );
    default:
  }
};

const ArrayOfItems = ({ source, label }) => {
  return (
    <>
      <SectionTitle label={label} variant="h4" />
      <ArrayInput source={source} label="">
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ getSource }) => (
              <MultiLanguageInputs
                label="name"
                sourcePl={getSource("name.pl")}
                sourceUa={getSource("name.ua")}
                required
                fullWidth
              />
            )}
          </FormDataConsumer>
          <SelectInput
            source="type"
            label="type"
            choices={[
              { id: "link", name: "link" },
              { id: "value", name: "value" },
            ]}
            defaultValue="link"
            resettable={true}
            validate={[reactAdminRequired()]}
            fullWidth
          />
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...rest }) =>
              renderInputsLinkValue(scopedFormData, getSource)
            }
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

export const FacilityCreateAndEdit = () => {
  const translate = useTranslate();
  return (
    <SimpleForm sx={{ maxWidth: 1000 }}>
      <SectionTitle label="NAME" variant="h4" />
      <MultiLanguageInputs
        label=""
        sourcePl="name.pl"
        sourceUa="name.ua"
        required
        fullWidth
      />
      <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <SelectInput
            source="type"
            label="type"
            choices={[
              { id: "clinic", name: "clinic" },
              { id: "hospital-ward", name: "hospital ward" },
            ]}
            resettable={true}
            validate={[reactAdminRequired()]}
            fullWidth
          />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <ReferenceInput
            source="info.parent_unit"
            reference="parent-unit"
            sort={{ field: "id", order: "ASC" }}
          >
            <SelectInput
              label="Parent Unit"
              optionText="name.pl"
              resettable={true}
              validate={[reactAdminRequired()]}
              fullWidth
            />
          </ReferenceInput>
        </Box>
      </Box>
      <SectionTitle label="ADDRESS" variant="h4" />
      <SectionTitle label="district" variant="h6" />
      <SelectInput
        label="district"
        source="info.district"
        choices={toChoices(districts)}
        format={(c) => c.pl}
        parse={(name) => districts.find(({ pl }) => pl === name)}
        required
        fullWidth
      />
      <MultiLanguageInputs
        label="city"
        sourcePl="info.city.pl"
        sourceUa="info.city.ua"
        required
        requiredUa
        fullWidth
      />
      <MultiLanguageInputs
        label="street"
        sourcePl="info.address.pl"
        sourceUa="info.address.ua"
        required
        fullWidth
      />
      <SectionTitle label="KEY INFORMATION" variant="h4" />
      <TextInput
        source="info.phone"
        label="phone"
        fullWidth
        validate={[reactAdminRequired()]}
      />
      <TextInput
        source="info.mail"
        label="mail"
        fullWidth
        validate={[reactAdminRequired()]}
      />
      <MultiLanguageInputs
        label="open hours"
        sourcePl="info.open_hours.pl"
        sourceUa="info.open_hours.ua"
        fullWidth
      />
      <MultiLanguageInputs
        label="how to entry the facility?"
        sourcePl="entry.pl"
        sourceUa="entry.ua"
        fullWidth
      />
      <SectionTitle label="parking" variant="h6" />
      <SelectInput
        source="parking.is_free"
        label="is free?"
        choices={[
          { id: true, name: "yes" },
          { id: false, name: "no" },
        ]}
        resettable={true}
        fullWidth
      />
      <MultiLanguageInputs
        label=""
        sourcePl="parking.value.pl"
        sourceUa="parking.value.ua"
        fullWidth
      />
      <MultiLanguageInputs
        label="accomodation"
        sourcePl="accomodation.pl"
        sourceUa="accomodation.ua"
        fullWidth
      />
      <MultiLanguageInputs
        label="registration"
        sourcePl="registration.pl"
        sourceUa="registration.ua"
        fullWidth
      />
      <SectionTitle label="SPECIALIZATION" variant="h4" />
      <ArrayInput source="specializations" label="">
        <SimpleFormIterator>
          <ReferenceInput
            source="type"
            reference="specialities"
            sort={{ field: "id", order: "ASC" }}
            perPage={1000}
          >
            <AutocompleteInput
              label="Select or start typing"
              fullWidth
              optionText="name.pl"
              optionValue="id"
              validate={[reactAdminRequired()]}
              variant="outlined"
            />
          </ReferenceInput>
          <SelectWithSearchAndAdd source="items" apiReference="tags" />
        </SimpleFormIterator>
      </ArrayInput>
      <SectionTitle label="AVAILABLE AT LOCATION" variant="h4" />
      <SectionTitle label="Patient's room" variant="h6" />
      <SelectWithSearchAndAdd
        source="at_location.patients_room.items"
        apiReference="at-location"
      />
      <SectionTitle label="Infrastructure" variant="h6" />
      <SelectWithSearchAndAdd
        source="at_location.infrastructure.items"
        apiReference="at-location"
      />
      <SectionTitle label="Feeding" variant="h6" />
      <SelectWithSearchAndAdd
        source="at_location.feeding.items"
        apiReference="at-location"
      />
      <SectionTitle label="WHAT TO TAKE?" variant="h4" />
      <SectionTitle label="child" variant="h6" />
      <SelectWithSearchAndAdd
        source="what_to_take.child.items"
        apiReference="what-to-take"
        filterValue="child"
        filterKey="types"
        createComponent={<WhatToTakeCreateInDialog />}
      />
      <SectionTitle label="parent" variant="h6" />
      <SelectWithSearchAndAdd
        source="what_to_take.parent.items"
        apiReference="what-to-take"
        filterValue="parent"
        filterKey="types"
        createComponent={<WhatToTakeCreateInDialog />}
      />
      <SectionTitle label="documents" variant="h6" />
      <SelectWithSearchAndAdd
        source="what_to_take.documents.items"
        apiReference="what-to-take"
        filterValue="documents"
        filterKey="types"
        createComponent={<WhatToTakeCreateInDialog />}
      />
      <SectionTitle label="worth to take" variant="h6" />
      <SelectWithSearchAndAdd
        source="what_to_take.worth_to_take.items"
        apiReference="what-to-take"
        filterValue="worth_to_take"
        filterKey="types"
        createComponent={<WhatToTakeCreateInDialog />}
      />
      <ArrayOfItems label="ADDITIONAL INFORMATION" source="additional_info" />
      <ArrayOfItems label="RULES" source="rules" />
      <WithRecord
        label="gallery"
        render={(record) => (
          <ObjectGallery
            source="gallery"
            reference="facility"
            config={{
              bucketName: BucketName,
              dirName: GalleryDirectory + "/" + record.id,
              region: Region,
              accessKeyId: AccessKeyId,
              secretAccessKey: SecretAccessKey,
            }}
            label={translate("Gallery")}
            uploadFileLabel={translate("Upload photo")}
          />
        )}
      />
    </SimpleForm>
  );
};
