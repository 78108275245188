import { Create } from "react-admin";
import { CreateAndEditValue } from "../../common/CreateAndEditValue";

const ParentUnitCreate = () => {
  return (
    <Create title="Create Parent Unit">
      <CreateAndEditValue
        label="Parent Unit"
        sourcePl={"name.pl"}
        sourceUa={"name.ua"}
        required
        fullWidth
      />
    </Create>
  );
};

export default ParentUnitCreate;
