import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField,
  WithRecord,
} from "react-admin";
import { Chip } from "@mui/material";

const WhatToTakeList = () => {
  return (
    <List>
      <Datagrid rowClick="edit">
        <TextField source="id" label="What To Take ID" />
        <TextField source="name.pl" label="PL name" />
        <TextField source="name.ua" label="UA name" />
        <WithRecord
          label="Types"
          render={(record) => (
            <>
              {record?.types?.map((type) => (
                <Chip label={type} />
              ))}
            </>
          )}
        />
        <EditButton basePath="what-to-take" />
        <DeleteButton basePath="what-to-take" />
      </Datagrid>
    </List>
  );
};

export default WhatToTakeList;
