import * as React from 'react';
import {
    useCreateSuggestionContext,
    useCreate,
} from "react-admin";
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
} from '@mui/material';

export const CreateNewSimpleDict = (props) => {
    const { filter, onCancel, onCreate } = useCreateSuggestionContext();
    const [valuePl, setValuePL] = React.useState(filter || '');
    const [valueUa, setValueUa] = React.useState('');
    const [create] = useCreate();

    const handleSubmit = event => {
        event.preventDefault();
        create(
            props.reference,
            {
                data: {
                    name: {
                        pl: valuePl,
                        ua: valueUa
                    }
                },
            },
            {
                onSuccess: ({ data }) => {
                    setValuePL('');
                    setValueUa('');
                    onCreate(data);
                },
            }
        );
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
                        <TextField
                            label="Polish"
                            multiline="true"
                            onChange={event => setValuePL(event.target.value)}
                            required
                            fullWidth={true}
                            value={valuePl}
                            variant="standard"
                            autoFocus
                        />
                    </Box>
                    <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
                        <TextField
                            label="Ukrainian"
                            multiline="true"
                            onChange={event => setValueUa(event.target.value)}
                            fullWidth={true}
                            value={valueUa}
                            variant="standard"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button type="submit">Save</Button>
                    <Button onClick={onCancel}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}