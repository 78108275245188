import { Create } from "react-admin";
import { CreateAndEditValue } from "../../common/CreateAndEditValue";

export const AtLocationCreate = () => (
  <Create title="Create At Location">
    <CreateAndEditValue
      label="At Location"
      sourcePl={"name.pl"}
      sourceUa={"name.ua"}
      required
      fullWidth
    />
  </Create>
);
