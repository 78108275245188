import PropTypes from 'prop-types';
import S3FileUpload from './ReactS3/S3FileUpload';
import {
    Box,
    Card,
    CardMedia,
    CardActions,
    CardHeader,
    Chip,
    Typography
} from "@mui/material";
import { DropzoneAreaBase } from "material-ui-dropzone";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
    Button,
    useRecordContext,
    useUpdate,
} from 'react-admin';

const ObjectGallery = ({
    config,
    label,
    source,
    reference,
    uploadFileLabel,
}) => {

    const [update] = useUpdate();
    const record = useRecordContext();
    const images = (!record?.gallery || !record?.gallery?.images) ? [] : record.gallery?.images;
    const mainImage = (!record?.gallery || !record?.gallery?.mainImage) ? 0 : record.gallery?.mainImage;

    const updateGallery = (newImages, newMainImage) => {
        if ( newImages || newMainImage ) {
            update(
                reference,
                {
                    id: record.id,
                    data: {
                        ...record,
                        gallery: {
                            mainImage: newMainImage,
                            images: newImages,
                        }
                    },
                    previousData: record
                }
            )
        }
    }

    const remove = (image) => {
        // we are not removing files from S3
        const newImages = images.filter(el => el.original !== image.original);
        const newMainImage = newImages.length <= mainImage ? 0 : mainImage;
        updateGallery(newImages, newMainImage);
    }

    const upload = (files) => {
        if ( files[0] ) {
            S3FileUpload.uploadFile(files[0]['file'], config)
            .then((data) => {
                const filtered = images.filter(el => el.original === data.location);
                const newImages = filtered.length === 0 ? images.concat([{
                                original: data.location,
                                thumbnail: data.location
                            }]) : images;
                updateGallery( newImages, mainImage );
            })
            .catch((err) => {
                console.log(err);
            })
        }
    }

    window.Buffer = window.Buffer || require("buffer").Buffer;

    return (<>
        <Typography variant={"h4"} gutterBottom>
            {label}
        </Typography>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
            <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
                <Typography variant={"h5"} gutterBottom>
                    {uploadFileLabel}
                </Typography>
                <DropzoneAreaBase
                    acceptedFiles={['image/*']}
                    onAdd={upload}
                    maxFileSize={5000000}
                />
            </Box>
        </Box>
        <Box sx={{pt:2}} display={{ xs: "block", sm: "flex", width: "100%" }}>
            {images.map( (image, index) => (
                <Card>
                    <CardHeader
                        avatar={index === mainImage ? <Chip label="Default"/> : (<Button size="small" onClick={() => {
                            updateGallery(images, index)
                        }}>Make default</Button>)}
                    />
                    <CardMedia
                        component="img"
                        height="194"
                        image={image.thumbnail}
                        alt={image.original}
                    />
                    <CardActions>
                        <Button
                            onClick={() => remove(image)}
                            size="small"
                        >
                            <DeleteIcon /> Remove photo
                        </Button>
                    </CardActions>
                </Card>
            ))}
        </Box>
    </>)
}

ObjectGallery.propTypes = {
    config: PropTypes.shape({
        bucketName: PropTypes.string.isRequired,
        dirName: PropTypes.string.isRequired,
        region: PropTypes.string.isRequired,
        accessKeyId: PropTypes.string.isRequired,
        secretAccessKey: PropTypes.string.isRequired,
    }),
    label: PropTypes.string.isRequired,
    uploadFileLabel: PropTypes.string.isRequired,
    record: PropTypes.any,
}

ObjectGallery.defaultProps = {
    label: "Gallery",
    uploadFileLabel: "Upload photo",
}

export default ObjectGallery;