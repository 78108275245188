import {
  SelectArrayInput,
  SimpleForm
} from "react-admin";
import { MultiLanguageInputs } from "../common/MultiLanguageInputs";
import { SectionTitle } from "../common/SectionTitle";

export const whatToTakeTypes = [
    {
        id: 'child',
        name: 'child',
        value: 'child',
    }, {
        id: 'parent',
        name: 'parent',
        value: 'parent',
    }, {
        id: 'documents',
        name: 'documents',
        value: 'documents',
    }, {
        id: 'worth_to_take',
        name: 'worth to take',
        value: 'worth_to_take',
    }
];

export const WhatToTakeCreateAndEdit = ({
    label,
    sourcePl,
    sourceUa,
    required,
    fullWidth
}) => {
    return (
        <SimpleForm>
            <MultiLanguageInputs
                label={label}
                sourcePl={sourcePl}
                sourceUa={sourceUa}
                required={required}
                fullWidth={fullWidth}
            />
            <SectionTitle label="types" variant="h6" />
            <SelectArrayInput
                optionText="name"
                optionValue="id"
                source="types"
                choices={whatToTakeTypes}
                required
            />
        </SimpleForm>
    );
};

