import * as React from "react";
import { Create } from "react-admin";
import { CreateAndEditValue } from "../../common/CreateAndEditValue";

export const SpecCreate = () => (
  <Create title="Create Speciality">
    <CreateAndEditValue
      label="Specialization"
      sourcePl={"name.pl"}
      sourceUa={"name.ua"}
      required
      fullWidth
    />
  </Create>
);
