import * as React from "react";
import { Edit } from "react-admin";
import { CreateAndEditValue } from "../../common/CreateAndEditValue";

export const SpecEdit = () => (
  <Edit title="Edit Speciality">
    <CreateAndEditValue
      label="Specialization"
      sourcePl={"name.pl"}
      sourceUa={"name.ua"}
      required
      fullWidth
    />
  </Edit>
);
