import {
  useTranslate,
} from "react-admin";
import { Typography } from "@mui/material";

export const SectionTitle = ({ label, variant }) => {
  const translate = useTranslate();

  return (
    <Typography variant={variant} gutterBottom>
      {translate(label)}
    </Typography>
  );
};
