import { Create } from "react-admin";
import { FacilityCreateAndEdit } from "../FacilityCreateAndEdit";

export const FacilityCreate = () => {
  return (
    <Create title="Create Facility">
      <FacilityCreateAndEdit />
    </Create>
  );
};
