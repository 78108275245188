import * as React from 'react';
import {
    ReferenceArrayInput,
    AutocompleteArrayInput,
} from "react-admin";
import { CreateNewSimpleDict } from './CreateNewSimpleDict';

export const SelectWithSearchAndAdd = ({
    source,
    apiReference,
    filterKey,
    filterValue,
    createComponent,
    optionText,
    optionValue
}) => {
    const simpleDictForm = <CreateNewSimpleDict reference={apiReference} />;
    return (<>
        <ReferenceArrayInput
            source={source}
            reference={apiReference}
            sort={{ field: 'id', order: 'ASC' }}
            perPage={1000}
        >
            <AutocompleteArrayInput
                label="Select or start typing"
                fullWidth
                optionText={optionText || "name.pl"}
                optionValue={optionValue || "id"}
                createItemLabel="+ Add new"
                createLabel="+ Add new"
                variant="outlined"
                create={createComponent || simpleDictForm}
                getOptionDisabled={(option) => filterKey && option.id !== '@@ra-create'? !option[filterKey]?.includes(filterValue) : false }
            />
        </ReferenceArrayInput>
    </>)
}