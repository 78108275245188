import { Edit } from "react-admin";
import { FacilityCreateAndEdit } from "../FacilityCreateAndEdit";

export const FacilityEdit = () => {
  return (
    <Edit title="Edit Facility">
      <FacilityCreateAndEdit />
    </Edit>
  );
};
