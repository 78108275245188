import * as React from "react";
import {
  Datagrid,
  DeleteButton,
  EditButton,
  List,
  TextField,
} from "react-admin";

export const SpecList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name.pl" />
      <TextField source="name.ua" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);
